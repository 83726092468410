import React from "react";
import {Navigate} from 'react-router-dom';
// import axios from 'axios';
import Menu from "../components/menu/Menu";
import FormMiCuenta from "../components/form-cuenta/MiCuenta";
import {Container} from 'react-bootstrap';
import Verify from "../components/verify/Verify"
// import { trackPromise } from 'react-promise-tracker';

const MiCuenta = () => {


    if(!sessionStorage.getItem('userData')){
        return <Navigate to="/"  />;
    }
    else{
        Verify()
    }
    
    return(
        <>
            <Menu />
            <Container>
                <h1 className="mt-4">Mi cuenta</h1>
                <FormMiCuenta />
                
            </Container>
        </>
    )
};

export default MiCuenta;
