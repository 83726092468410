import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';

class Logout extends Component {

render() {

/*
if (!this.state.redirectToReferrer || !sessionStorage.getItem('userData')){
return (<Redirect to={'/'}/>)
}
*/
//console.log(sessionStorage.getItem('userData'));
sessionStorage.removeItem("userData");
sessionStorage.clear();
return (<Navigate to={'/login'}/>)
//return (localStorage);

}
}
export default Logout;