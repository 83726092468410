import axios from 'axios';

function Verify(){

    const now           = Date.now();
    const ahora1        = new Date(now)
    const ahora         = ahora1.toISOString()
    
    let ud              = sessionStorage.getItem('userData');
    let userData        = JSON.parse(ud);

    // console.log(userData.fin_token)
    // console.log(ahora)

    if(ahora > userData.fin_token){
        
        console.log("ya se vencio")

        let ud              = sessionStorage.getItem('userData');
        let userData        = JSON.parse(ud);


        const addHours = (date, hours) => {
            const hoursToAdd = hours * 60 * 60 * 1000;
            date.setTime(date.getTime() + hoursToAdd);
            return date;
        }

        const instancex = axios.create({
            baseURL: '',
            timeout: 5000,
            headers: {'content-type': 'application/x-www-form-urlencoded'}
        });

        instancex.post('/api/oauth/token', new URLSearchParams({
            refresh_token: userData.refresh_token,
            grant_type: 'refresh_token'
        }),{
            auth: {
            username: "express-client",
            password: "express-secret"
            }})
        .then(response => {
            
            let token = response.data.access_token;
            
            if(token){

                let actual_data = response.data
                //------------------------------------------------- 
                const now           = Date.now();
                const ahora         = new Date(now)
                const inicio_token  = ahora.toISOString()
                const fin_token2    = addHours(ahora, 1);
                const fin_token     = fin_token2.toISOString();
                actual_data         = { ...actual_data, inicio_token: inicio_token, fin_token: fin_token };
                // ------------------------------------------------

                console.log("👉 Se renovó el token");
                sessionStorage.removeItem("userData");
                sessionStorage.clear();
                sessionStorage.setItem('userData',JSON.stringify(actual_data));
                window.location.reload();
            }
            else{
                console.log("👉 Acceso incorrecto");
            }

        })
        .catch(function (error) {
            console.log(error);  
        });

    }else{
        console.log("token activo ("+userData.fin_token+")")
    }
}

export default Verify;