import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Menu() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">Dominga</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="blogs">Blogs</Nav.Link>
            <Nav.Link href="contact">Contact</Nav.Link>
            <Nav.Link href="productos">Productos</Nav.Link>
            
          </Nav>
          <Nav>
            <NavDropdown title="Logout" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="usuarios">Usuarios</NavDropdown.Item>
              <NavDropdown.Item href="my-account">Mi cuenta</NavDropdown.Item>
              <NavDropdown.Item href="logout">Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Menu;