import React, { useState, useRef } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import "./login.css";
import axios from 'axios';
import {Navigate} from 'react-router-dom';
import BackgroundImage from "../../assets/images/background.png";
import Logo from "../../assets/images/logo.png";
import ReCAPTCHA from "react-google-recaptcha"
// import { SpinningCircles } from 'react-loading-icons'

const Login = () => {
  const [inputUsername, setInputUsername]     = useState("");
  const [inputPassword, setInputPassword]     = useState("");
  const [redirectTrue, setRedirectTrue]       = useState();
  const [show, setShow]                       = useState(false);
  const [loading, setLoading]                 = useState(false);
  const recaptcha = useRef()

  if(redirectTrue){
    return <Navigate to="/productos"  />;
  }

  if(!sessionStorage.getItem('userData')){

      const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        
        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
          alert('Por favor complete el desafío reCAPTCHA.')
          setLoading(false);
        } 

        const addHours = (date, hours) => {
          const hoursToAdd = hours * 60 * 60 * 1000;
          date.setTime(date.getTime() + hoursToAdd);
          return date;
        }

        const instance = axios.create({
          baseURL: '',
          timeout: 5000,
          headers: {'content-type': 'application/x-www-form-urlencoded'}
        });

        // ------------------- check recaptcha ---------------------------


        instance.post('/api/verify', new URLSearchParams({
              respuesta: captchaValue
          }))
          .then(response => {
            
              if(response.data.success){
                
                  // ------------- send data to get oauth token -----------------
                  instance.post('/api/oauth/token', new URLSearchParams({
                    username: inputUsername, 
                    password: inputPassword,
                    grant_type: 'password'
                  }),{
                  auth: {
                    username: "express-client",
                    password: "express-secret"
                  }})
                  .then(response => {
          
                    let token = response.data.access_token;
                  
                    if(token){
                        let actual_data = response.data
                        
                        const now           = Date.now();
                        const ahora         = new Date(now)
                        const inicio_token  = ahora.toISOString()
                        const fin_token2    = addHours(ahora, 1);
                        const fin_token     = fin_token2.toISOString();
                        actual_data         = { ...actual_data, inicio_token: inicio_token, fin_token: fin_token };
    
                        console.log("👉 Acceso correcto");
                        sessionStorage.setItem('userData',JSON.stringify(actual_data));
                        setRedirectTrue(true)
                    }
                    else{
                        console.log("👉 Acceso incorrecto");
                        setShow(true);
                        setLoading(false);
                    }
                  })
                  .catch(function (error) {
                    //console.log(error);
                    //recaptcha.current.reset();
                    window.grecaptcha.reset();
                    console.log("👉 Acceso incorrecto - "+error);
                        setShow(true);
                        setLoading(false);
                  });

                  // ------------- end send data to get oauth token -----------------
              }

          })
          .catch(function (error) {
              console.log(error);
            });   
      };


      const handlePassword = () => {};

      return (
        <div
          className="sign-in__wrapper"
          style={{ backgroundImage: `url(${BackgroundImage})` }}
        >
          {/* Overlay */}
          <div className="sign-in__backdrop"></div>
          {/* Form */}
          <Form className="shadow p-4 bg-white rounded" autoComplete="off" onSubmit={handleSubmit}>
            {/* Header */}
            <img
              className="img-thumbnail mx-auto d-block mb-2"
              src={Logo}
              alt="logo"
            />
            <div className="h4 mb-2 text-center">Bienvenido</div>
            {/* ALert */}
            {show ? (
              <Alert
                className="mb-2"
                variant="danger"
                onClose={() => setShow(false)}
                dismissible
              >
                Accesos incorrectos, por favor intente nuevamente.
              </Alert>
            ) : (
              <div />
            )}
            <Form.Group className="mb-2" controlId="username">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={inputUsername}
                placeholder="Email" 
                autoComplete="off"
                onChange={(e) => setInputUsername(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="password">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                autoComplete="new-password"
                value={inputPassword}
                placeholder="Contraseña"
                onChange={(e) => setInputPassword(e.target.value)}
                required
              />
            </Form.Group>
            <ReCAPTCHA 
              className="mt-3 mb-3" 
              sitekey="6Lf9wVkqAAAAAC6puLvMGYQ2sTM8whTRlSkiCJcz" 
              ref={recaptcha} />

            {/* <Form.Group className="mb-2" controlId="checkbox">
            <span className="small"><Form.Check type="checkbox" label="Recordarme en este dispositivo" /></span>
            </Form.Group> */}
            {!loading ? (
              <Button className="w-100" variant="primary" type="submit">
                Entrar
              </Button>
            ) : (
              <Button className="w-100" variant="primary" type="submit" disabled>
                Accesando...
              </Button>
            )}
            <div className="d-grid justify-content-end">
              <Button
                className="text-muted px-0"
                variant="link"
                onClick={handlePassword}
              >
                <span className="small">¿Olvidó su contraseña?</span>
              </Button>
            </div>
          </Form>
          {/* Footer */}
          <div className="w-100 mb-2 position-absolute bottom-0 start-50 translate-middle-x text-white text-center">
            Hostland | &copy;2024
          </div>
        </div>
      );

  }
  else{
    setRedirectTrue(true)
  }
};

export default Login;
