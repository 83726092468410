import {Container} from 'react-bootstrap';
import Menu from "../components/menu/Menu";
import Verify from "../components/verify/Verify"
import {Navigate} from 'react-router-dom';

const Contact = () => {

    if(!sessionStorage.getItem('userData')){
        return <Navigate to="/"  />;
    }
    else{
        Verify()
    }

    return (
      <>
      <Menu />
      <Container>
        <h1 className="mt-4">Contacto</h1>
      </Container>

      
      </>);
};

export default Contact;