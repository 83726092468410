import { Container, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import "./form.css";

function FormCuenta() {

    const [inputNombre, setInputNombre]                 = useState("");
    const [inputTelefono, setInputTelefono]             = useState("");
    const [inputUsername, setInputUsername]             = useState("");
    const [showLoader, setShowLoader]                   = useState(false);
    const [inputFoto, setInputFoto]                     = useState("");
    const [image, setImage]                             = useState({ preview: '', data: '' })
    const [showAlert, setShowAlert]                     = useState(false);
    const [guardando, setGuardando]                     = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();

        setGuardando(true);

        if( inputNombre && inputTelefono && inputUsername){
          
            // var data_send = {
            //   "name": inputNombre,
            //   "phone": inputTelefono,
            //   "username": inputUsername,
            //   "photo": image.data
            // }

            // console.log(data_send);


            let ud = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);
    
            // const instance = axios.create({
            //         baseURL: '',
            //         timeout: 5000,
            //         headers: {'content-type': 'multipart/form-data', 'Authorization': 'Bearer '+userData.access_token}
            //         });

            const formData = new FormData();
            formData.append('_method', 'put');
            formData.append("name", inputNombre)
            formData.append("phone", inputTelefono)
            formData.append("username", inputUsername)
            formData.append("photo", image.data)
                    
                
            fetch("api/update-profile", {
                method: "PUT",
                body: formData,
                // headers: {'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+userData.access_token}
                headers: {'Authorization': 'Bearer '+userData.access_token}
              })
              .then(response => {
                if(response.status === 200)
                    setShowAlert(true);
                    getData()
                    setImage("")
                    setGuardando(false);
              })
              .catch(function (error) {
                console.log(error);
              });
        
        }
        else{
            alert("Faltan Datos, por favor complete el formulario");
            setGuardando(false);
        }

    };

    const getData= async () => {
        
        setShowLoader(true);
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(
            await instance.get('api/my-profile')
            .then(response => {
                //console.log(response)
                // setData(response.data[0])
                setInputNombre(response.data[0].name)
                setInputUsername(response.data[0].username)
                setInputTelefono(response.data[0].phone)
                if(response.data[0].photo !== null)
                    setInputFoto("https://storage.googleapis.com/bucket-oauth-v2/"+response.data[0].photo)
                else
                    setInputFoto("")

                setShowLoader(false);
            })
        //)
    }


    const handleFileChange = (e) => {
        
        const img = {
          preview: URL.createObjectURL(e.target.files[0]),
          data: e.target.files[0],
        }
        setImage(img)
      }


    useEffect(()=>{
        getData()
        },[])

  return (
        <>
            <Container>

                <Alert className="mt-4" key="success" variant="success" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                    Se guardó exitosamente la información.
                </Alert>

                {showLoader ? 
                    <div className='loader-div'>
                        Loading...
                    </div> : ""}
                

                <Row className="justify-content-md-center mt-4">
                    <Col md="4" className='text-center'>
                        <img alt="Profile Display" src={inputFoto || "images/profile.png"} className="image-profile rounded-circle mt-5 mb-5" />
                    </Col>
                    <Col md="8">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label htmlFor="input_email">Email</Form.Label>
                                <Form.Control 
                                id="input_email"
                                type="email" 
                                placeholder="Email"
                                defaultValue={inputUsername}
                                onChange={(e) => setInputUsername(e.target.value)}
                                required />
                                {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                                </Form.Text> */}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label htmlFor="input_nombre">Nombre</Form.Label>
                                <Form.Control 
                                id="input_nombre"
                                type="text" 
                                placeholder="Nombre del producto"
                                defaultValue={inputNombre}
                                onKeyUp={(e) => setInputNombre(e.target.value)}
                                required />
                                {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                                </Form.Text> */}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label htmlFor="input_foto">Teléfono</Form.Label>
                                <Form.Control 
                                id="input_foto"
                                type="phone" 
                                placeholder="Teléfono"
                                defaultValue={inputTelefono}
                                onKeyUp={(e) => setInputTelefono(e.target.value)}
                                required />
                                {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                                </Form.Text> */}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label htmlFor="input_foto">Foto</Form.Label>
                                <Form.Control 
                                id="input_foto"
                                type="file" 
                                placeholder="Foto"
                                onChange={handleFileChange}
                                required />
                                {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                                </Form.Text> */}
                        </Form.Group>
                        
                        {!guardando ? (
                            <Button variant="primary" type="submit" onClick={(handleSubmit)}>
                                Guardar
                            </Button>
                        ) : (
                            <Button variant="primary" type="submit" disabled>
                                Guardando...
                            </Button>
                        )}
                        
                    </Col>
                </Row>
                
                
            </Container>
        </>
  )
}

export default FormCuenta;