import { useState, useEffect } from 'react';
import { Table, Form } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { TiDelete } from "react-icons/ti";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import Alert from 'react-bootstrap/Alert';

function TableProductos() {

    const [show, setShow]                                       = useState(false);
    const [showDelete, setShowDelete]                           = useState(false);
    const [showAdd, setShowAdd]                                 = useState(false);
    const [deleteId, setDeleteId]                               = useState(false);
    const [items, setItems]                                     = useState([]);
    
    const [inputNombre, setInputNombre]                         = useState("");
    const [inputAlmacen, setInputAlmacen]                       = useState("");
    const [inputExistencias, setInputExistencias]               = useState("");

    const [inputIdEdit, setInputIdEdit]                         = useState("");
    const [inputNombreEdit, setInputNombreEdit]                 = useState("");
    const [inputAlmacenEdit, setInputAlmacenEdit]               = useState("");
    const [inputExistenciasEdit, setInputExistenciasEdit]       = useState("");

    const [showAlert, setShowAlert]                             = useState(false);
    // const [showAlertError, setShowAlertError]                   = useState(true);


    const handleClose = () => setShow(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleCloseAdd = () => setShowAdd(false);


    const handleShow = (item_id) => {
        setShow(true);
    
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
            instance.get('api/get-product/'+item_id)
            .then(response => {
                setInputIdEdit(response.data.data[0].id_product)
                setInputNombreEdit(response.data.data[0].nombre)
                setInputAlmacenEdit(response.data.data[0].almacen)
                setInputExistenciasEdit(response.data.data[0].existencias)
            })

    }

    const handleShowAdd = () => {
        setShowAdd(true);
    }

    const handleShowDelete = (item_id) => {
        setShowDelete(true);
        setDeleteId(item_id)
    }

    const  handleDelete = () => {
        deleteProduct(deleteId)
    }


    const getData= async () => {
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(
            await instance.get('api/get-products')
            .then(response => {
                setItems(response.data)
            })
        //)
    }

    useEffect(()=>{
        getData()
        },[])


    const deleteProduct=(id_prod)=>{
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        trackPromise(
            instance.delete('api/delete-product/'+id_prod)
            .then(response => {
                //console.log(response.status)
                if(response.status === 200){
                    setShowDelete(false);
                    setDeleteId("")
                    getData()
                }
            })
        );
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if( inputNombre && inputAlmacen && inputExistencias){
          
          var data_send = {
            "nombre": inputNombre,
            "almacen": inputAlmacen,
            "existencias": inputExistencias
          }
    
          let ud = sessionStorage.getItem('userData');
          let userData = JSON.parse(ud);
    
          const instance = axios.create({
                  baseURL: '',
                  timeout: 5000,
                  headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                  });
            
          instance.post('api/add-product', data_send)
          .then(response => {

              if(response.data.status === "200"){
                setInputNombre("");
                setInputExistencias("");
                setShowAdd(false)
                setShowAlert(true)
                getData()
              }
          })
          .catch(function (error) {
           
                if(error.response.status === "401"){
                  console.log("se venció el token, please update it")
                  window.location.replace("./refresh-token");
                  
                }
          });
    
    
        }
        else{
          //return error
          alert("Faltan datos");
        }
        
      }

      const handleSubmitEdit = async (event) => {

        event.preventDefault();
    
        if( inputNombreEdit && inputAlmacenEdit && inputExistenciasEdit){
          
          var data_send = {
            "nombre": inputNombreEdit,
            "almacen": inputAlmacenEdit,
            "existencias": inputExistenciasEdit
          }
    
          let ud = sessionStorage.getItem('userData');
          let userData = JSON.parse(ud);
    
          const instance = axios.create({
                  baseURL: '',
                  timeout: 5000,
                  headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                  });
            
            instance.put('/api/update-product/'+inputIdEdit, data_send)
            .then(response => {
                //console.log(response)
                if(response.data.status === "200"){
                    // setInputNombreEdit("");
                    // setInputExistenciasEdit("");
                    setShow(false)
                    setShowAlert(true)
                    getData()
                }
            })
            .catch(function (error) {
                if(error.response.status === "401"){
                console.log(error)
                
                }
            });
    
    
        }
        else{
          //return error
          alert("Faltan datos");
        }
        
      }
    

  return (
    <>
    <FaEdit onClick={() => handleShowAdd()}  />

    <Alert className="mt-4" key="success" variant="success" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        El producto se guardó exitosamente
    </Alert>


    <Table striped bordered hover>
      <thead>
        <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Almacen</th>
            <th>Existencias</th>
            <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
      {
        items && items.length>0 && items.map((item)=>
            <tr>
                <td>{item.id_product}</td>
                <td>{item.nombre}</td>
                <td>{item.almacen}</td>
                <td>{item.existencias}</td>
                <td><FaEdit onClick={() => handleShow(item.id_product)}  /> <TiDelete onClick={() => handleShowDelete(item.id_product)} /> </td>
            </tr>
        )
      }
      </tbody>
    </Table>

        <Modal show={showAdd} onHide={handleCloseAdd}>
            <Modal.Header closeButton>
            <Modal.Title>Agregar producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_nombre">Nombre</Form.Label>
                    <Form.Control 
                    id="input_nombre"
                    type="text" 
                    placeholder="Nombre del producto"
                    // defaultValue={}
                    onKeyUp={(e) => setInputNombre(e.target.value)}
                    required />
                    {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label htmlFor="input_almacen">Almacen</Form.Label>
                    <Form.Select 
                    id="input_almacen"
                    aria-label="Default select example"
                    onChange={(e) => setInputAlmacen(e.target.value)}
                    required>
                    <option>Almacen</option>
                    <option value="Frios">Frios</option>
                    <option value="Abarrotes">Abarrotes</option>
                    <option value="Vinos">Vinos</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_existencias">Existencias</Form.Label>
                    <Form.Control 
                    id="input_existencias"
                    type="number" 
                    placeholder="Existencias"
                    onKeyUp={(e) => setInputExistencias(e.target.value)}
                    required />
                    {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>

                <Button variant="primary" type="submit" onClick={(handleSubmit)}>
                    Guardar
                </Button>
            
            </Modal.Body>
        </Modal>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Editar producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_nombre_edit">Nombre</Form.Label>
                    <Form.Control 
                    id="input_nombre_edit"
                    type="text" 
                    placeholder="Nombre del producto"
                    defaultValue={inputNombreEdit || ""}
                    onKeyUp={(e) => setInputNombreEdit(e.target.value)}
                    required />
                    {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label htmlFor="input_almacen_edit">Almacen</Form.Label>
                    <Form.Select 
                    id="input_almacen_edit"
                    aria-label="Default select example"
                    value={inputAlmacenEdit || ""}
                    onChange={(e) => setInputAlmacenEdit(e.target.value)}
                    required>
                    <option>Almacen</option>
                    <option value="Frios">Frios</option>
                    <option value="Abarrotes">Abarrotes</option>
                    <option value="Vinos">Vinos</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_existencias_edit">Existencias</Form.Label>
                    <Form.Control 
                    id="input_existencias_edit"
                    type="number" 
                    defaultValue={inputExistenciasEdit || ""}
                    placeholder="Existencias"
                    onKeyUp={(e) => setInputExistenciasEdit(e.target.value)}
                    required />
                    {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>

                <Button variant="primary" type="submit" onClick={(handleSubmitEdit)}>
                    Guardar
                </Button>
            
            </Modal.Body>
        </Modal>
        

        <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
            <Modal.Title>Eliminar producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro que desea eliminar este producto?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
                Cancelar
            </Button>
            <Button variant="danger" onClick={() => handleDelete()} >
                Confirmar
            </Button>
            </Modal.Footer>
        </Modal>
        
    </>
  );
}

export default TableProductos;